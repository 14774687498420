import React from 'react'
    const Contacts = ({ contacts }) => {
      return (
        
        <div>
          
          {contacts.map((contact) => (
            <div className="card" key={contact.id}>
              <div className="card-body">
              <h2 className="card-title">{contact.id}</h2>

              {contact.state.map((s) => (
                <div id={s.anchor}>
                  <h3 className="card-header">{s.stateName}</h3>   
                  <ul class="list-group list-group-flush">
                  {s.centers.map((c, index) => (
                    <li class="list-group-item">
                    <div  >
                      <h5 className="card-title"><a href={c.website} target='_blank' rel="noopener noreferrer">{c.name}</a></h5>
                      <p className="card-text">{c.about}</p>

                      <table cellPadding='5'>
                    <tbody>
                        
                    <tr>
                        <td valign='top'>
                            {
                                c.donateLink > '' &&
                                <p className="card-text"><a href={c.donateLink} target='_blank'  rel="noopener noreferrer"><img width="150" src='../donate.png' alt={c.name}></img></a></p>
                            }
                            {c.petition > '' &&
                                <p className="card-text"><a href={c.petition} target='_blank'  rel="noopener noreferrer"><img width="150" src='../petition.png' alt={c.name}></img></a></p>
                            }

                        
                        
                        </td>

                        
                        
                        

                    {c.social.map((so) => (
                   <td valign='top' key={so.platform + so.handle}><a rel="noopener noreferrer" target='_blank' href={'https://www.' + so.platform + '.com/' + so.handle}><img width='50' alt={so.platform} src={so.platform + '.png'}></img></a></td>
                ))}
                    </tr>
                    </tbody>
                </table>
                    </div></li>
                    ))}  
                    </ul>
                </div>
                ))}
                
              
                
                
              </div>
            </div>
          ))}
        </div>
      )
    };
    export default Contacts