   // src/App.js

   import React, { Component } from 'react';
   import Contact from './components/contacts';
   class App extends Component {
    state = {
      contacts: []
    }

    componentDidMount() {
      var myInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
       // mode: 'no-cors',
        cache: 'default'
      };

      //let myRequest = new Request('http://planetzeebs.com/donations.json', myInit)
      let myRequest = new Request('../donations.json', myInit)
      fetch(myRequest)
      .then(res => res.json())
      .then((data) => {
        this.setState({ contacts: data })
      })
      .catch(console.log)
    }

     render() {
       return (
         <div>
          <Contact contacts={this.state.contacts} />
         </div>
         
       );
     }
   }

   export default App;